//import axios from 'axios';
import api from './api';
import UploadService from './uploadfiles.service';
//import authHeader from './auth.header';

//const API_URL = 'http://127.0.0.1:8080/api/secure/';

class TeamService {
  getTeamsForDivision(divisionId) {
    return api.get('secure/team/' + divisionId);
  }

  addTeamToDivision(team, divisionId, currentImage) {
    api.post('secure/team/' + divisionId, team).then(
      (response) => {
        this.team = response.data;
        console.log("team" + this.team);
        console.log("team id", this.team.id);
        this.upload(currentImage, this.team.id);
      },
      (error) => {
        console.error("Something went wrong saving the team and logo", error);
      }
    );

    return this.getTeamsForDivision(divisionId);
  }

  upload(currentImage, team) {
    UploadService.uploadTeam(currentImage, team, (event) => {
      this.progress = Math.round((100 * event.loaded) / event.total);
    })
      .then((response) => {
        this.message = response.data.message;
        return UploadService.getFiles();
      })
      .then((images) => {
        this.imageInfos = images.data;
      })
      .catch((err) => {
        this.progress = 0;
        this.message = "Could not upload the image! " + err;
        this.currentImage = undefined;
      });
  }

}

export default new TeamService();